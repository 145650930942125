




























































































import { defineComponent } from "@vue/composition-api";
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from "pinia";
import { mapActions } from "vuex";
import AssignMemberButton from "@/components/elements/AssignMemberButton.vue";
import FixedActionBar from "@/components/elements/FixedActionBar.vue";
import { clubApi } from "@/lib/backend";
import type { IomodelsLidVoorFeed } from "@/lib/backend/club.api";
import { formatErrorMessage } from "@/lib/formatErrorMessage";
import { useCurrentGym } from "@/lib/hooks/useCurrentGym";
import { toast } from "@/lib/vue2-sonner";
import { useAuthStore } from "@/pinia/auth";
import { useCoachStore } from "@/pinia/coach";
import { dagMaandJaar } from "@/utils/date";
import { getIconName, getMemberImage } from "@/utils/images";

export default defineComponent({
	name: "LedenAannemen",
	components: {
		FixedActionBar,
		AssignMemberButton,
	},
	setup() {
		const { id: gymId } = useCurrentGym();

		return { gymId };
	},
	data() {
		return {
			getIconName,
			getMemberImage,
			dagMaandJaar,
			selectedMembers: [] as IomodelsLidVoorFeed[],
		};
	},
	computed: {
		...mapPiniaState(useCoachStore, {
			membersPresentWithoutCoach: "membersPresentWithoutCoach",
			userId: "id",
		}),
		...mapPiniaState(useAuthStore, {
			roles: "roles",
			isClubAanvoerder: "isClubAanvoerder",
		}),
	},
	watch: {},
	created() {
		this.getCoach();
	},
	methods: {
		...mapPiniaActions(useCoachStore, { getCoach: "get" }),
		...mapActions("modal", {
			openModal: "openModal",
		}),
		selectItem(event: MouseEvent, id: number) {
			const target = event.target as HTMLElement;
			const checkbox = target as HTMLInputElement;

			if (checkbox) {
				const exist = this.selectedMembers.find((member) => member.id === id);
				if (exist) {
					this.selectedMembers = this.selectedMembers.filter((member) => member.id !== id);
				} else {
					const member = this.membersPresentWithoutCoach.find((member) => member.id === id);
					if (member) this.selectedMembers.push(member);
				}
			}
		},

		reset() {
			this.getCoach();
			this.selectedMembers = [];
		},

		openAanvoerderFlow() {
			const callback = this.reset;
			this.openModal({
				name: "assign-members",
				data: {
					memberIds: this.selectedMembers.map((member) => member.id),
					callback,
				},
			});
		},

		openCoachFlow() {
			this.openModal({
				name: "confirm",
				data: {
					amount: this.selectedMembers.length,
					message: `Weet je zeker dat je ${this.selectedMembers.length} ${this.selectedMembers.length === 1 ? "lid" : "leden"} wil aannemen?`,
				},
				callback: async () => {
					await toast
						.promise(
							clubApi.coach.ledenToewijzen(this.userId, {
								ledenIds: this.selectedMembers.map((member) => member.id),
							}),
							{
								loading:
									this.selectedMembers.length === 1 ? "Lid toewijzen..." : "Leden toewijzen...",
								success: this.selectedMembers.length === 1 ? "Lid toegewezen" : "Leden toegewezen",
								error: formatErrorMessage,
								finally: this.reset,
							},
						)
						?.unwrap();
				},
			});
		},

		openAssignMemberModal() {
			if (this.isClubAanvoerder) {
				this.openAanvoerderFlow();
			} else {
				this.openCoachFlow();
			}
		},
	},
});
